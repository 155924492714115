.TabContainer {
  display: flex;
  justify-content: left;
  align-items: center;
}

.tabHeading {
  margin: 3rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.activeTabLine {
  height: 2px;
  width: 100px;
  margin-top: 4px;
  background-color: coral;
  position: relative;
  top: 0;
}

/* .inputes {
  width: 100%;
  display: flex;
  justify-content: space-around;
} */

/* .inputesC1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 10px;
} */

/* .inputes .inputField {
  width: 20%;
  padding-left: 5px;
  margin-right: 10px;
} */

.PhoneNo {
  padding: 0 100px;
}

/* Recept */
.ReceptContainer {
  border: 2px solid #c0c0c0;
  margin: 10px 0;
  overflow: hidden;
  width: 100%;
  /* overflow: scroll; */
}
.ReceptBg {
  background-color: #f8f9fa;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.companyName {
  /* background-color: #343a40; */
  color: #000;
  /* padding-left: 10px; */
  padding: 15px 10px;
  display: inline-block;
  margin-right: 15px;
}

.DropDowns {
  width: 500px;
}

.CustomDropDown {
  padding: 10px 40px;
}

/* inputs */

.inputesC1 .inputField {
  width: 20%;
  margin-right: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.inputesInquiry .inputField {
  width: 17%;
  margin-right: 11px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.inputes2 .inputField {
  width: 20%;
  /* margin-right: 20px; */
  flex-wrap: wrap;
  margin-bottom: 20px;
  display: inline-block;
  margin-top: 20px;
}

.AmountDisplay {
  border: 1px solid #343a40;
  width: 111px;
  padding: 11px 9px;
  display: inline-block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-right: 30px;
}

.AddButton {
  /* margin-left: px; */
  background-color: green;
  margin-top: 5px;
}

.Dropdown1 {
  margin-right: 70px;
}

/* radio dropDown */
.ToggleClassBtnRadio {
  width: 300px;
}
.ToggleClassBtnRadio1 {
  width: 300px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputFieldp {
  width: 240px;
  margin-top: 10px;
  display: inline-block;
}
.inputFieldproduct {
  width: 240px;
  display: inline-block;
}

.inputes2 .inputField {
  width: 126px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.AddButtons {
  /* display: block !important; */
  /* width: 100%; */
  width: 120px;
}

.titles {
  overflow: auto;
}

.CPointer {
  cursor: pointer;
}

/* media query */

@media (max-width: 1024px) {
  .ToggleClassBtnRadio {
    width: 200px;
  }
  .ToggleClassBtnRadio1 {
    width: 200px;
  }
  .inputes2 .inputField {
    width: 126px;
  }
  .inputesInquiry .inputField {
    width: 22%;
  }
  .inputFieldp {
    width: 200px;
  }
  .AddButtons {
    margin-top: 20px !important ;
  }
}

@media (max-width: 768px) {
  .DropDowns {
    width: 400px;
  }

  .PhoneNo {
    padding: 0 50px;
  }
  .ToggleClassBtnRadio {
    width: 150px;
  }
  .ToggleClassBtnRadio1 {
    width: 150px;
  }
  .inputesC1 .inputField {
    width: 28%;
  }
  .inputesInquiry .inputField {
    width: 28%;
  }
  .inputes2 .inputField {
    width: 134px;
  }
  .ReceptContainer {
    border: 2px solid #c0c0c0;
    margin: 10px 0;
    /* overflow: hidden; */
    width: 100%;
    overflow: scroll;
  }
}

@media (max-width: 425px) {
  .DropDowns {
    width: 300px;
  }
  .inputesC1 .inputField {
    width: 92%;
    /* margin-right: 20px; */
    flex-wrap: wrap;
    margin-bottom: 26px;
  }
  .inputesInquiry .inputField {
    width: 92%;
    /* margin-right: 20px; */
    flex-wrap: wrap;
    margin-bottom: 26px;
  }
  .inputes2 .inputField {
    width: 92%;
    /* margin-right: 20px; */
    flex-wrap: wrap;
    margin-bottom: 26px;
  }
  .ToggleClassBtnRadio {
    width: 200px;
    margin: 10px 20px;
  }
  .ToggleClassBtnRadio1 {
    width: 170px;
    margin: 10px 10px;
  }
  .inputes2 .inputField {
    width: 180px;
  }
  .AddButtons {
    display: block !important;
    width: 100%;
    margin-top: 0px !important ;
  }
  .inputFieldp {
    width: 90%;
  }

  /* .btnQuote {
    margin-top: 50px;
  } */
}
