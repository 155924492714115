.my_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: #e9ecef;
}

.my_p {
    font-size: 18px;
    margin-bottom: -20px;
}

.Heading {
    font-size: 2rem;
    padding-bottom: 10px;
}

.card_1 {
    background-color: white;
    align-items: center;
    width: 350px;
    padding: 20px;
}

.my_css {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
}

.last_section {
    padding-top: 1 5px;
}
